@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* @tailwind base; */
@tailwind components;
@tailwind utilities;

h3 {
  box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #D5DAE1;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
}
h1 {
  box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #D5DAE1;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
}
h2 {
  box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #D5DAE1;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
}
svg {
  display: block;
    vertical-align: middle;
}

:root {
  --blue-rgb: 237 245 255;
  --green-rgb: 125 161 35;
}

body {
  font-family: "Work Sans", sans-serif;
}

body:has(.card[data-color="blue"]:hover) {
  background-color: rgb(var(--blue-rgb) / 25%);
}

body:has(.card[data-color="green"]:hover) {
  background-color: rgb(var(--green-rgb) / 25%);
}

@layer utilities {
  .max-container {
    @apply max-w-5xl mx-auto sm:p-16 pb-12 !pt-[126px] px-8 min-h-[calc(100vh-80px)];
  }

  .head-text {
    @apply sm:text-5xl text-3xl font-semibold sm:leading-snug font-poppins;
  }

  .subhead-text {
    @apply font-semibold sm:text-3xl text-xl relative font-poppins;
  }

  .blue-gradient_text {
    @apply bg-gradient-to-r from-[#00c6ff] to-[#0072ff] bg-clip-text text-transparent;
  }

  .input {
    @apply bg-white border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mt-2.5 font-normal shadow-card;
  }

  .textarea {
    @apply block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-200 focus:ring-blue-500 focus:border-blue-500 mt-2.5 font-normal shadow-card;
  }

  .btn {
    @apply text-white bg-gradient-to-r from-[#00c6ff] to-[#0072ff] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center;
  }

  .header {
    @apply flex justify-between items-center sm:px-16 px-8 py-4 max-w-5xl mx-auto absolute top-0 bg-transparent z-10 right-0 left-0;
  }

  .footer {
    @apply max-w-5xl mx-auto sm:px-16 pb-6 px-8 flex flex-col gap-7;
  }

  .footer-container {
    @apply flex flex-wrap gap-7 items-center justify-between;
  }

  .info-box {
    @apply mx-5 relative flex text-white flex-col gap-3 max-w-2xl neo-brutalism-blue pt-4 pb-12 px-8;
  }

  .neo-btn {
    @apply py-3 px-6 rounded-lg text-blue-500 text-center font-semibold sm:w-1/2 w-[90%] -bottom-5 absolute mx-auto right-0 left-0 flex justify-center items-center gap-3;
  }

  .cta {
    @apply w-full flex items-center md:flex-row flex-col sm:mt-16 mt-8 gap-7;
  }

  .cta-text {
    @apply text-black-500 font-extrabold flex-1 text-3xl max-md:text-center;
  }
}

.glassmorphism {
  background: rgba(8, 116, 239, 0.07);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.logo {
  border-radius: 8.889px;
  background: #fff;
  box-shadow: 0px 10px 35px -4px rgba(67, 83, 255, 0.15),
    0px 1.5px 4px -1px rgba(67, 83, 255, 0.2);
}

.block-container {
  position: relative;
  transition: 250ms;
  perspective: 500px;
}

.block-container .btn-back {
  position: absolute;
  inset: 0;
  z-index: -1;
  width: inherit;
  height: inherit;
  transition: 250ms;
  transform-style: preserve-3d;
  transform-origin: bottom right;
  transform: rotateZ(15deg);
  will-change: transform;
  box-shadow: 16px 0 40px #e4e4e4;
}

.block-container .btn-back-red {
  background: linear-gradient(135deg, #ff4b1f -20%, #ff9068 120%);
}

.block-container .btn-back-green {
  background: linear-gradient(135deg, #adfda2 -20%, #11d3f3 120%);
}

.block-container .btn-back-yellow {
  background: linear-gradient(135deg, #f7971e -20%, #ffd200 120%);
}

.block-container .btn-back-blue {
  background: linear-gradient(135deg, #0061ff -20%, #60efff 120%);
}

.block-container .btn-back-orange {
  background: linear-gradient(135deg, #ff0f7b -20%, #f89b29 120%);
}

.block-container .btn-back-pink {
  background: linear-gradient(135deg, #e81cff -20%, #40c9ff 120%);
}

.block-container .btn-back-black {
  background: linear-gradient(135deg, #0a1647 -20%, #e4e7e4 120%);
}

.block-container .btn-front {
  position: absolute;
  inset: 0;
  z-index: 1;
  width: inherit;
  height: inherit;
  background-color: #ffffff33;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  transition: 250ms;
  transform-style: preserve-3d;
  transform-origin: top left;
  overflow: hidden;
}

.block-container:hover > .btn-back {
  transform: translateZ(20px) rotateZ(15deg) rotateX(-20deg) rotateY(-20deg);
}

.block-container:hover > .btn-front {
  transform: translateZ(80px) translateY(-5px) rotateX(15deg) rotateY(15deg);
}

/* Neo Brutalism */
.neo-brutalism-blue {
  background: #2b77e7;
  position: relative;
  border-radius: 10px;
  border: #2b77e7;
  box-shadow: 0.6vmin 0.6vmin #336cc1, 1vmin 1vmin #0092db, 1vmin 1vmin #0092db,
    0.65vmin 1vmin #0092db, 1vmin 0.65vmin #0092db;
}

.neo-brutalism-white {
  background: #fff;
  border: #fff;
  box-shadow: 0.6vmin 0.6vmin #fff, 1vmin 1vmin #d2e4ff, 1vmin 1vmin #d2e4ff,
    0.65vmin 1vmin #d2e4ff, 1vmin 0.65vmin #d2e4ff;
}

.header {
  background-color: #fff;
  height: 5em;
  left: 0;
  justify-content: center;
  padding: 1rem var(--side-margin);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 300;
}

.headerContainer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.headerLogo {
  margin: auto;
}

.leftMenu {
  max-width: 25rem;
  -webkit-overflow-scrolling: touch;
  background-color: black;
  /* bottom: 0; */
  -webkit-box-shadow: 2px 0 10px rgb(0 0 0 / 15%);
  box-shadow: 2px 0 10px rgb(0 0 0 / 15%);
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex; */
  left: 0;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.3s ease-in-out;
  will-change: transform;
  overflow-y: auto;
  position: fixed;
  top: 5.5rem;
  /* top: var(--header-height); */
  -ms-touch-action: auto;
  touch-action: auto;
  width: 100%;
  z-index: 200;
  position: "absolute";
}

.leftMenu.visible {
  transform: translate3d(0, 0, 0);
}

.Left > li:hover {
  transform: translate(30px, 0px);
}

.element:hover {
  transform: translate(-30px, 0px);
}

.elementSelect {
  transform: translate(-30px, 0px);
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.menuItem {
  width: 14rem;
  height: "84px";
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #eae9e9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1.2rem;
}

.menuItem:hover {
  /* background-color: #f9eefe; */
  /* transform: translate(42px, 0px); */
}

.menuItemImage {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 0.75rem;
  width: 3.75rem;
}

.menuItemContent {
  width: 100%;
  padding-left: 30px;
}

.menuItemContenth3 {
  /* font-size: 1.5rem !important; */
  text-wrap: wrap;
  border-bottom: 2px solid transparent;
  line-height: 1.2;
  margin: 0 0 -2px;
  max-width: calc(100vw - 96px);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.leftMenu::-webkit-scrollbar {
  width: 0px;
}

.leftMenu::-webkit-scrollbar-thumb {
  background-color: #6e6e6e;
}

.LeftItem {
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  width: 13rem;
  height: 78px;
  display: flex;
  justify-content: center;
}

.LeftItem:hover {
  transform: translate(30px, 0px);
}

.element:hover {
  transform: translate(-30px, 0px);
}
.RMenu {
  top: 8em;
  overflow-y: scroll;
  overflow-x: hidden;
  /* max-width: 25rem; */
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  bottom: 0;
  -webkit-box-shadow: 2px 0 10px rgb(0 0 0 / 15%);
  box-shadow: 2px 0 10px rgb(0 0 0 / 15%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  right: 0px;
  /* left: 75.6vw; */
  overflow-y: auto;
  position: fixed;
  /* top: var(--header-height); */
  -ms-touch-action: auto;
  touch-action: auto;
  /* width: 100%; */
  z-index: 200;
}
.RMenu::-webkit-scrollbar {
  width: 0px;
}

.RMenu::-webkit-scrollbar-thumb {
  background-color: #6e6e6e;
}
.galleryItem {
  margin: 0;
  min-height: 150px;
  max-height: 290px;
  min-width: 900px;
  max-width: 1200px;
  display: flex;
  flex-flow: row nowrap;
  border-radius: 40px 0px !important;
  background: rgba(255, 255, 255, 0.9);
  overflow: hidden;
  /* overflow: auto; */
  /* box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25); */
}

.galleryItemImg {
  max-height: 250px;
  width: 290px;
  margin-right: 20px;
}

.galleryItemText {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.galleryItemContent {
  width: 100%;
  overflow: auto;
  margin-top: 10px;
  margin-right: 15px;
  /* padding-right: 10px; */
}
.galleryItemTitle {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.togglePhoto {
  background-repeat: no-repeat;
}
.toggleTitle {
  margin-top: 15px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
}
.RMenu .toggle{
  margin-top: 10px;
}
.documentItem {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
  padding: 10px;
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  text-align: center;
  width: 17vw;
  height: 96px;
  top: 20px;
  left: 14px;
  padding: 26px, 227.83px, 26px, 9.17px;
  border-radius: 10px;
  background: #f9f9f9;
  cursor: pointer;
}
.documentItem:hover {
  background: #ededed;
}
.aloneDocuments {
  overflow: auto;
  padding: 0px;
  right: 0.5vw;
  width: 19.5vw;
  min-height: 40vh;
  max-height: 80vh;
  /* display: grid; */
  /* grid-gap: 20px; */
  justify-content: center;
  position: fixed;
  top: 8em;
  z-index: 100;
  box-shadow: none;
  background-color: transparent;
  /* border-radius: 40px 0px 00px 0px; */
}

.aloneItem {
  margin-right: 10px;
  right: 20vw;
  width: 63vw;
  min-height: 30vh;
  max-height: 80vh;
  display: grid;
  grid-gap: 20px;
  justify-content: center;
  position: fixed;
  top: 8em;
  z-index: 100;
  box-shadow: none;
}
.aloneItem .buttonBack {
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 10px;
  position: absolute;
  top: 50px;
  width: 131px;
  height: 38px;
  left: 0px;
  transition: left 0.3s;
  background-color: #0570b8;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  /* border: 1px; */
}
.aloneItem .buttonBack:hover {
  left: -10px;
}
.aloneItem .containerAloneItem {
  position: relative;
  max-height: 79vh;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  left: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  max-width: 58vw;
  border-top-left-radius: 50px;
}

.aloneItem .containerAloneItem .title {
  font-family: Inter;
  font-size: 28px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  /* position: absolute; */
  /* background: #ffffffb6; */
  top: 1em;
  padding: 5px 10px;
  border-radius: 20px;
  left: 40px;
}
.aloneItem .containerAloneItem .more {
  padding: 10px;
}
.aloneItem .image {
  width: 58vw;
  min-height: 33.5vh;
  max-height: 500px;
}
